<template>
  <div class="companies-page-wrapper">
    <v-row class="companies-header-nav">
      <admin-tabs />
      <v-spacer></v-spacer>
      <div class="header-action">
        <create-company-dialog
          @createdCompany="addCompanyToList"
        ></create-company-dialog>
      </div>
      <div class="header-search pl-15">
        <v-text-field
          v-model="search"
          clearable
          color="blur"
          solo
          dense
          hide-details
          clear-icon="mdi-close"
          prepend-inner-icon="mdi-magnify"
          label="Search for value"
          @input="searchInput"
        ></v-text-field>
      </div>
    </v-row>
    <v-data-table
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :page="page"
      item-key="username"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      :headers="headers"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
      }"
      @click:row="clickCompany"
      class="elevation-1"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th width="50"></th>
            <th>
              <div class="th-wrapper">
                <label class="th-lb">Companies name</label>
                <v-select
                  v-model="sortby_company"
                  :items="groups"
                  multiple
                  dense
                  hide-details
                  autowidth
                  :menu-props="{ maxHeight: '800' }"
                  class="select-fit"
                >
                  <template v-slot:selection=""> </template>
                </v-select>
              </div>
            </th>
            <th>
              <div class="th-wrapper">
                <label class="th-lb">Participants</label>
                <v-select
                  v-model="sortby_participants"
                  :items="sorting_order"
                  dense
                  hide-details
                  autowidth
                  class="select-fit"
                  @change="updateSorting('participants', sortby_participants)"
                  ><template v-slot:selection=""> </template>
                </v-select>
              </div>
            </th>
            <th>
              <span class="th-lb">Access to servers</span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.picture="{ item }" class="td-picture">
        <v-avatar class="company-avatar">
          <img
            :src="
              item.picture
                ? item.picture
                : require('@/assets/images/company_logos/loading.png')
            "
            alt="John"
          />
        </v-avatar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import AdminTabs from "../AdminTabs";
import { Constants } from "@/config/constants";
import CreateCompanyDialog from "./CreateCompanyDialog";

export default {
  components: {
    CreateCompanyDialog,
    AdminTabs,
  },
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      filter: {},
      sortDesc: false,
      sortBy: "username",
      page: 1,
      itemsPerPage: 12,
      sortby_company: "",
      sortby_participants: "",
      sorting_order: Constants.SortingOrder,
      headers: Constants.CompanyTableHeaders,
      users: [],
      companies: [],
      groups: [],
      emptyObject: {},
      notify: "",
      notification: "",
      notificationColor: "",
      addEditUser: "",
      editedItem: {},
      dialogDelete: false,
      dialog: false,
      dialogEdit: false,
      search: "",
      selected: [],
      errorMessage: null,
      isLoading: true,
      dialogCreateCompany: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    filteredItems() {
      if (this.sortby_company.includes("nocompany")) return this.companies;
      else
        return this.companies.filter((u) =>
          this.sortby_company.includes(u.Username)
        );
    },
  },
  mounted() {
    this.reloadTable();
  },
  methods: {
    updateSorting(value, order) {
      this.sortBy = value;
      this.sortDesc = order == "Ascending" ? false : true;
    },
    reloadTable() {
      this.companies = [];
      this.emptyObject = {};
      let self = this;
      awsAuth
        .getAllCompanies()
        .then((resp) => {
          self.companies = resp.data.map((company) => {
            return {
              username: company.Username,
              picture: company.Attributes.find((x) => x.Name == "picture")
                ?.Value,
              participants: company.participants,
            };
          });
          self.groups = self.companies.map((c) => c.username);
          self.sortby_company = self.groups;
        })
        .catch((err) => {
          console.log("err = " + err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addCompanyToList() {
      this.isLoading = true;
      this.reloadTable();

      // this.companies.push({
      //   username: addedcompany.Username,
      //   picture: addedcompany.Attributes.find((x) => x.Name == "picture")
      //     ?.Value,
      //   participants: 0,
      // });
    },
    getUserGroups(user) {
      awsAuth.getUserGroups();
      return "groups: " + user.username;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    clickCompany(item) {
      this.$router.push(`/admin/companies/${item.username}`);
    },
    searchInput(value) {
      if (value) {
        this.search = value.replaceAll(' ', '_');
      }
    }
  },
};
</script>
