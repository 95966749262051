<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-plus-box</v-icon>
        Create company
      </v-btn>
    </template>
    <v-card class="create-company__card pa-8">
      <v-card-title>
        <span>Create company</span>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog" color="gray">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <p class="text-subhead">
        Enter information about your company
      </p>
      <v-card-text>
        <v-form ref="companyform" lazy-validation>
          <v-text-field
            label="Company name"
            v-model="form.username"
            maxlength="15"
            outlined
            prepend-inner-icon="$vuetify.icons.buildingIcon"
            height="60px"
            :rules="nameRules"
            required
            @input="companyNameInput"
          />
          <v-select
            :items="servers"
            label="Server"
            v-model="form.server"
            prepend-inner-icon="$vuetify.icons.serverIcon"
            outlined
            height="60px"
          ></v-select>
          <v-select
            v-model="form.people"
            :items="unassignedUsers"
            item-text="username"
            :label="unassignedUsers.length > 0 ? 'Select people' : 'No people without company'"
            chips
            multiple
            :disabled="unassignedUsers.length === 0"
            :menu-props="{ bottom: true, offsetY: true, closeOnClick: false }"
            prepend-inner-icon="$vuetify.icons.friendsIcon"
            no-data-text="No people without company"
            outlined
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                :key="item.username"
                v-bind="attrs"
                :input-value="selected"
                @click:close="parent.selectItem(item)"
              >
                <v-avatar
                  class="primary white--text text-h7"
                  left
                  v-text="item.username.slice(0, 1).toUpperCase()"
                ></v-avatar>
                <span class="pr-2">
                  {{ item.username }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                >
                  $delete
                </v-icon>
              </v-chip>
            </template>
<!--            <template v-slot:prepend-item>-->
<!--              <div class="px-2 py-0">-->
<!--                <v-text-field-->
<!--                  append-icon="mdi-magnify"-->
<!--                  color="grey lighten-2"-->
<!--                  hide-details-->
<!--                  :label="`Search...`"-->
<!--                  outline-->
<!--                  single-line-->
<!--                  v-model="filter"-->
<!--                ></v-text-field>-->
<!--              </div>-->
<!--              <v-divider class="my-2"></v-divider>-->
<!--            </template>-->
          </v-select>
          <div class="logo-upload-wrapper">
            <div class="icon-preview">
              <v-img
                ref="companyLogo"
                :src="
                  logoSrc
                    ? logoSrc
                    : require('@/assets/images/fileupload-icon.png')
                "
                width="50"
                height="50"
                style="cursor: pointer;"
                @click="triggerFileInput"
              ></v-img>
            </div>
            <div class="main-file-upload">
              <v-file-input
                hide-input
                class="image-target ml-3"
                v-model="files"
                accept="image/png, image/jpeg, image/jpg, image/bmp"
                prepend-icon=""
                ref="logoFileInput"
                @change="handleUpdate"
              >
                <template slot="append-outer">
                  <div class="mt-n3">
                    <p class="text-link text-link-md"
                       @click="triggerFileInput"
                       style="cursor: pointer;">
                      Upload logotype
                    </p>
                    <span class="text-sm">PNG or JPEG, 2MB</span>
                  </div>
                </template>
              </v-file-input>
            </div>
          </div>
          <v-dialog v-model="dialogCropLogo" width="500">
            <v-card>
              <v-card-text>
                <VueCropper
                  v-show="selectedFile"
                  ref="cropper"
                  :src="selectedFile"
                  alt="Source Image"
                  :autoCropArea="1"
                  :aspectRatio="1 / 1"
                  :initialAspectRatio="1 / 1"
                ></VueCropper>
              </v-card-text>
              <v-card-actions
                class="image-crop--action"
                style="padding-left: 10px !important"
              >
                <v-btn
                  class="primary"
                  @click="cropImage(), (dialogCropLogo = false)"
                  >Crop</v-btn
                >
                <v-btn color="primary" text @click="dialogCropLogo = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="primary"
          height="60px"
          :disabled="!this.form.username"
          id="action-submit"
          type="submit"
          :loading="isUpdating"
          @click="onCreateCompany"
        >
          Register
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import { mapActions } from "vuex";
import { Constants } from "@/config/constants";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  props: {},
  components: {
    VueCropper,
  },
  data() {
    return {
      dialog: false,
      // myForm: {},
      form: {},
      isAdmin: false,
      /* Validation */
      nameRules: [
        (v) => !!v || "Company name is required",
      ],
      groups: [],
      allGroups: [],
      role: "",
      company: "",
      servers: Constants.Servers,
      allRoles: Constants.AllRoles,
      people: [],
      unassignedUsers: [],
      files: null,
      avatarRules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      dialogCropLogo: false,
      mime_type: "",
      croppedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      logoSrc: "",
      isUpdating: false,
      // filter: null
    };
  },
  computed: {
    // filteredUsers() {
    //   if (this.filter) {
    //     const pattern = new RegExp(`\b?${this.filter}\b?`, 'ig');
    //     return this.unassignedUsers.filter(item => item.username.toLowerCase().match(pattern));
    //   } else {
    //     return this.unassignedUsers;
    //   }
    // },
  },

  mounted() {
    let self = this;
    this.isAdmin = awsAuth.isUserAdmin();

    awsAuth
      .getAllGroups()
      .then((resp) => {
        self.allGroups = resp.data
          .map((x) => x.GroupName)
          .filter((item) => self.allRoles.indexOf(item) === -1);
      })
      .catch((err) => {
        console.log(err);
      });

    this.getUnassignedUsers();
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    getUnassignedUsers() {
      this.unassignedUsers = [];
      let self = this;
      awsAuth
        .getUsersOfCompany("nocompany")
        .then((res) => {
          self.unassignedUsers = res.data.map((user) => {

            return {
              username: user.Username,
              name: user.Attributes.find((x) => x.Name == "name")?.Value,
              group: user.Attributes.find((x) => x.Name == "family_name")
                ?.Value,
            };
          });
        })
        .catch(() => {
          self.unassignedUsers = [];
        })
        .finally(() => {});
    },
    async onCreateCompany() {
      // Form Validation
      let validated = this.$refs.companyform.validate();

      if (!validated) return;
      this.isUpdating = true;
      awsAuth
        .getCompany(this.form.username)
        .then( async res => {
          if (!res.data.data) {

            //upload image to AWS s3
            if (this.croppedImage) {
              await this.saveImage();
            }

            let user = {
              username: this.form.username,
              picture: this.form.picture || '',
            };

            awsAuth
              .createCompany(user)
              .then((res) => {
                this.$emit("createdCompany", res.data.data.User);
                if (this.form.people) {
                  this.onAddUser();
                }
                this.snackbar({
                  color: "success",
                  icon: "mdi-check-circle",
                  title: "Success",
                  text: `${this.form.username} created`,
                });
              })
              .catch((error) => {
                console.log(error.message);
              })
              .finally(() => {
                this.closeDialog();
                this.isUpdating = false;
              });

          } else {
            this.snackbar({
              color: "error",
              icon: "mdi-check-circle",
              title: "Error",
              text: `This company already exists.`,
              timeout: 2500
            });
            this.isUpdating = false;
            return;
          }
        })
    },
    onAddUser() {
      Promise.all(
        this.form.people.map((user) => {
          return this.updateUsersAttributes(user, this.form.username);
        })
      )
        .then(() => {
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `New users are added to ${this.form.username} company`,
          });
          this.form = {};
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async updateUsersAttributes(username, companyName) {
      return awsAuth
        .adminUpdateAttributes(username, {
          family_name: companyName,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    handleUpdate() {
      this.dialogCropLogo = true;

      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        console.log(e);
        this.selectedFile = e.target.result;
        this.$refs.cropper.replace(this.selectedFile);
      };

      fileReader.readAsDataURL(this.files);
    },
    cropImage() {
      this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.logoSrc = this.croppedImage;
    },
    async saveImage() {
      console.log("saving image");

      //make request to upload image to s3
      const payload = {
        fileContent: this.croppedImage,
        username: this.form.username
          ? this.form.username
          : `company-logo${Math.random()
              .toString()
              .substr(2, 5)}`,
      };
      await awsAuth
        .uploadImage(payload)
        .then((res) => {
          console.log(res.data);
          this.form.picture = res.data.data.Location;
        })
        .catch((error) => {
          this.notification = `ERROR, ${error.message}!`;
          this.notificationColor = "yellow darken-4";
        })
        .finally(() => {
          this.notify = true;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.form = {}
      this.logoSrc = "";
      this.croppedImage = null;
      this.files = null;
    },
    triggerFileInput() {
      this.$refs.logoFileInput.$refs.input.click();
    },
    companyNameInput(value) {
      this.form.username = value.replaceAll(' ', '_')
    }
  },
};
</script>
<style scoped>
.image-target >>> .v-icon__component {
  height: 50px;
  width: 50px;
}
.avatar-company {
  width: 170px;
  border-radius: 50%;
}
.logo-upload-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
}
.logo-upload-wrapper .icon-preview .v-image {
  border-radius: 50%;
}
</style>
